@import 'src/assets/styles/mixins.scss';

.dateInputPicker {
    width: 100%;

    .inputWrapper {
        width: 100%;

        :global(.MuiOutlinedInput-root) {
            font-size: inherit;
        }

        input {
            width: 100%;
            height: auto;
            padding: 14px 10px 15px 10px;
            outline: none;
            color: var(--white-color);
            z-index: 1;
        

            @include media-xxl {
                font: var(--font-m);
            }

            &::placeholder {
                font-size: inherit;
                color: var(--white-color);
                @include roboto-light-italic();
            }


            @include media-xxl {
                padding-top: 21px;
                padding-bottom: 21px;
            }
        }

        fieldset {
            background-color: var(--primary-color);
            border-color: var(--primary-color);
            border-radius: 5px;

            &:global(.MuiOutlinedInput-notchedOutline)  {
                border: none;
            }
            

            
            @include media-xxl {
                border-radius: 10px;
            }
        }

        :global {
            .MuiFormControl-root {
                width: 100%;
                outline: none;

                .Mui-focused {
                    fieldset {
                        transition: border-color .2s;
                        border: 1px solid var(--accent-color);

                    }
                }

                .MuiInputAdornment-root {
                    z-index: 1;
                }

            }

        }
    }

    &.error {
        fieldset {
           border: 1px solid var(--error-color) !important;
        }
    }
}

.calendar {
    :global {
        .MuiDateCalendar-root {
            background-color: var(--primary-color);
            color: var(--white-color);
    
            .MuiDayCalendar-weekDayLabel {
                color: var(--white-color);
            }
    
            .MuiPickersCalendarHeader-root {
                .MuiButtonBase-root {
                    color: var(--white-color)
                }
            }
    
    
            .MuiDayCalendar-weekContainer {
                .MuiPickersDay-root {
                    color: var(--white-color);
    
    
                    &.Mui-disabled {
                        color: var(--gray-dark-color);
                    }
    
                    &.Mui-selected {
                        background-color: var(--accent-color) !important;
                    }
    
                    &.MuiPickersDay-today {
                        border-color: var(--accent-color);
                    }
    
                    &:hover {
                        background-color: var(--accent-light-color)
                    }
                }
            }
        }
    
    }
    
}

