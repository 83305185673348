@import 'src/assets/styles/mixins.scss';
@import 'src/assets/styles/variables.module';

.box {
    width: 100%;
    padding: 20px;
    position: relative;
    min-width: 545px;
    text-align: center;

    @include media-xxl {
        padding: 25px;
    }

    .header {
        display: flex;
        background: transparent;
        outline: none;
        border: none;
    }

    .logo {
        margin: 0 auto;
        width: 200px;
        height: auto;
    }


    .close {
        position: absolute;
        cursor: pointer;
        right: 25px;
        top: 25px;
    }

    .text {
        font-size: var(--font-size-m);
        color: var(--gray-dark-color);
    }
}

.body {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 35px;
    margin-top: 20px;


    .icon {
        width: 48px;
        height: auto;

        @include media-xxl {
            width: 80px;
            height: 80px;
        }
    }

    .title {
        font-size: 30px;
        font-weight: 700;
    }

}
