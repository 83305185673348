@import 'src/assets/styles/mixins.scss';

.input {
    .iconWrapper {
        position: relative;

        input {
            width: 100%;
            padding: 10px 0 10px 10px;
            border: 1px solid var(--border-color);
            border-radius: 5px;
            outline: none;
            font-size: inherit;

            @include media-xxl {
                padding-top: 20px;
                padding-bottom: 20px;
                border-radius: 10px;
            }

            &:focus {
                transition: border-color .2s;
                border-color: var(--accent-color) !important;
            }

            &::placeholder {
                font-size: inherit;
                @include roboto-light-italic();
            }
        }

        input[type="password"] {
            padding-right: 50px;

            &::placeholder {
                @include roboto-light-italic();
            }
        }

        .passwordIcon {
            width: 14px;
            height: 14px;
            position: absolute;
            right: 10px;
            top: 11px;
            color: var(--gray-dark-color);

            cursor: pointer;

            @include media-xxl {
                top: 20px;
                right: 15px;
                width: 24px;
                height: 24px;
            }
        }

        .icon {
            position: absolute;
            top: 15px;
            left: 10px;
        }
    }

    &:global(.dark) {
        .iconWrapper {
            input {
                background-color: var(--primary-color);
                border-color: var(--primary-color);
                color: var(--white-color);

                &::placeholder {
                    color: var(--gray-dark-color);
                }
            }
        }
    }

    &:global(.light) {
        // [TODO]: Fix styles
    }


    &.readonly {
        opacity: 0.7;
    }

    &.error {
        .iconWrapper {
            input {
                border-color: var(--error-color);
            }
        }
    }

    &.withIcon {
        .iconWrapper {
            input {
                padding-left: 40px;
            }
        }
    }
}