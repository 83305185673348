@import 'src/assets/styles/mixins.scss';

.box {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 15px;
    background-color: var(--secondary-color);

    @include media-xxl {
        padding: 26px 36px;
    }

    &.resetBox {
        padding: 0;
        border-radius: 0;
    }


    .titleBox {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 24px;
        position: relative;

        @include media-xxl {
            gap: 15px;
            margin-bottom: 30px;
        }

        .title {
            font-size: var(--font-size-m);
            color: var(--white-color);
            font-weight: 500;

            @include media-xxl {
                font-size: var(--font-size-l);
            }
        }
    }

    .icon {
        display: flex;
        align-items: center;

        svg {
            height: 16px;
            width: 16px;

            @include media-xxl {
                height: 24px;
                width: 24px;
            }
        }
    }
}
