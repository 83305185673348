@import 'src/assets/styles/mixins.scss';

.container {
    max-width: var(--modal-width);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 35px;

    @include media-xxl {
        padding: 40px;
    }


    .title {
        margin-bottom: 26px;
        font-size: var(--font-size-heading);
        @include roboto-medium();


        svg {
            fill: var(--accent-color);
            margin-right: 15px;
        }

        @include media-xxl {
            margin-bottom: 44px;
        }
    }


    .subtitle {
        margin-bottom: 5px;
        font-size: var(--font-size-sm);
        @include roboto-medium();

        @include media-xxl {
            font-size: 22px;
            margin-bottom: 20px;
        }
    }

    .description {
        width: 100%;
        margin-bottom: 20px;
        padding-right: 30px;
        color: var(--gray-color);
        @include roboto-light();

        @include media-xxl {
            margin-bottom: 25px;
        }
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        button {
            max-height: 42px;
            width: 141px;

            @include media-xxl {
                max-height: 65px;
                width: 220px;
            }
        }
    }
}