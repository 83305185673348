@import 'src/assets/styles/mixins.scss';


.rolesTable {
    display: flex;
    flex-direction: column;

    height: 100%;
    padding: 30px 40px;

    @include media-xxl {
        padding: 30px 60px;
    }

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;


        .title {
            font-size: var(--font-size-m);
            color: var(--white-color);
            margin-right: 27px;

            @include roboto-medium();

            @include media-xxl {
                font-size: var(--font-size-heading1);
            }
        }

        .addBtn {
            width: auto;
            height: 34px;
            padding: 10px 20px;
            border-radius: 5px;
            @include roboto-regular();


            @include media-xxl {
                height: 50px;
                padding: 20px 40px;
                border-radius: 10px;
            }
        }
    }

    .tableContainer {
        height: 100%;
        background-color: var(--secondary-color);
        border-radius: 15px;

        .table {
            align-self: flex-start;

            .role {
                position: relative;
                display: inline;
                padding: 6px 18px;
                border-radius: 10px;
                text-transform: capitalize;
                color: var(--white-color);
            }

            .description {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .teamMembers {
                p {
                    color: var(--accent-color);
                    margin-bottom: 4px;
                    cursor: pointer;
                }
            }



            .list {
                list-style: none;


                li::before {
                    content: "• ";
                    position: relative;
                    top: 3px;
                    font-size: var(--font-size-l);
                    color: var(--accent-color); /* or whatever color you prefer */
                  }
            }

            .action {
                display: flex;
                svg {
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                    cursor: pointer;

                    @include media-xxl {
                        width: 20px;
                        height: 20px;
                        margin-right: 16px;
                    }
                }
                
            }


        }
    }
}
