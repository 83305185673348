@import 'src/assets/styles/mixins.scss';

.container {
    display: flex;
    flex-direction: column;

    height: 100%;
    padding: 25px 40px;

    @include media-xxl {
        padding: 30px 40px;
    }


    .header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        justify-content: space-between;

        @include media-xxl {
            margin-bottom: 20px;
        }

        .title {
            font-size: 18px;
            color: var(--white-color);
            font-weight: 500;

            @include media-xxl {
                font-size: 28px;
            }
        }
    }

    .forms {
        display: grid;
        grid-template-columns: 1.35fr 1fr;
        gap: 20px;

        @include media-xxl {
            grid-template-columns: 1.3fr 1fr;
        }
    }

    .rightBox {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .loaderBox {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .btn {
        padding: 10px 20px;
        max-width: 120px;
        font-size: var(--font-size-xs);

        @include media-xxl {
            padding: 12px 25px;
            max-width: 164px;
            font-size: var(--font-size-sm);
        }
    }
}
