@import 'src/assets/styles/mixins.scss';

.container {
    width: 100%;
    padding: 20px;

    @include media-xxl {
        padding: 25px;
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        //align-items: center;

        .logo {
            width: 100px;
            height: 75px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .close {
            margin-top: 16px; // TODO: Fix
            cursor: pointer;
        }

        .back {
            margin-top: 14px; // TODO: Fix
            cursor: pointer;
        }

    }

    .body {

        .balance {
            width: 100%;
            display: flex;
            justify-content: space-between;

            padding-bottom: 20px;
            border-bottom: 1px solid #515151;

            .title,
            .value {
                font-size: var(--font-size-l);
                font-weight: 600;
            }
        }
    }

    .form {
        margin-bottom: 20px;
    }

    .next {
        display: flex;
        justify-content: center;
        width: 100%;
        button {
            max-width: 300px;

        }
    }
}
