@import 'src/assets/styles/mixins.scss';

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;

    max-width: 510px;

    @include media-xxl {
        max-width: 770px;
    }


    .title {
        margin-bottom: 30px;
        font-size: var(--font-size-heading);
        @include roboto-medium();

        @include media-xxl {
            margin-bottom: 45px;
        }
    }

    .form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: 20px;
        row-gap: 17px;
        width: 100%;
        

        .field {
            width: 50%;
        }

        @include media-xxl {
            row-gap: 25px;
            margin-bottom: 30px;
        }
    }

    .actions {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;

        button {
            width: 140px;

            @include media-xxl {
                width: 220px;
            }
        }
    }
}