@import 'src/assets/styles/mixins.scss';

.container {
    .title {
        margin-bottom: 46px;
        color: var(--accent-color);
        font-size: 20px;
        @include roboto-medium();

        @include media-xxl {
            font-size: var(--font-size-heading1);
            margin-bottom: 95px;
        }
    }

    .input {
        margin-bottom: 20px;

        @include media-xxl {
            margin-bottom: 25px;

        }

    }

    .forgotPassword {
        color: var(--accent-color);
        margin-top: 5px;
        margin-bottom: 15px;
        margin-left: 2px;
        font-size: var(--font-size-xs);
        cursor: pointer;
        @include roboto-regular();

        @include media-xxl {
            font-size: 17px;
            margin-top: 10px;
            margin-bottom: 22px;
        }
    }

    .button {
        margin-top: 20px;
    }

    .signUpLink {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 7px;
        font-size: var(--font-size-xs);

        @include media-xxl {
            font-size: 17px;
            margin-top: 11px;
        }

        a {
            margin: 0;
            margin-left: 4px;
            color: var(--accent-color);
            text-decoration: none;
        }
        
    }
}