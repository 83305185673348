@import 'src/assets/styles/mixins.scss';

.field {
    .fieldName {
        margin-bottom: 4px;
        font-size: inherit;
        white-space: nowrap;

        @include media-xxl {
            margin-bottom: 6px;
        }
    }

    .mandatory {
        color: var(--error-color);
    }

    .optional {
        font-size: var(--font-size-sm);
        color: var(--gray-color);
        font-weight: 300;
    }

    &:global(.dark) {
        .fieldName {
            color: var(--white-color);
        }
    }

    &.error {
        .fieldName {
            color: var(--error-color);
        }

        .errorText {
            margin-top: 2px;
            color: var(--error-color);
            margin-left: 2px;
            font-size: var(--font-size-xs);
            margin-bottom: -8px;

            @include media-xxl {
               font-size: 17px; 
            }
        }
    }


}
