@import 'src/assets/styles/mixins.scss';

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: var(--white-color);
    border-radius: 10px;
    border: 1px solid var(--accent-color);
    background-color: rgba(91, 192, 235, 0.08);
    padding: 15px;
    margin-bottom: 20px;

    @include media-xxl {
        margin-bottom: 30px;
    }

    .info {
        margin-right: 15px;
        .title {
            font-size: var(--font-size-heading);
            margin-bottom: 5px;
            @include roboto-medium();

            @include media-xxl {
                font-size: var(--font-size-heading0);
            }
        }

        .description {
            font-size: var(--font-size-s);
            @include roboto-light();

            @include media-xxl {
                font-size: var(--font-size-m);
            }
        }
    }



    .redirect {
        width: 140px;
        height: 35px;

        @include media-xxl {
            width: 177px;
            height: 50px;
        }
    }
}