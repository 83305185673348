@import 'src/assets/styles/mixins.scss';

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px;

    @include media-xxl {
        padding: 0 20px;
    }

    .title {
        margin-bottom: 30px;
        font-size: var(--font-size-heading);

        @include roboto-medium();

        @include media-xxl {
            margin-bottom: 50px;
        }
    }

    .avatar {
        margin-bottom: 20px;
    }

    .form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;

        gap: 10px;
        width: 100%;
        margin-bottom: 20px;

 

        .field {
            flex: 1 1 calc(50% - 5px)
        }
    }

    .actions {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;

        button {
            width: 140px;

            @include media-xxl {
                width: 220px;
            }
        }
    }
}