@import 'src/assets/styles/mixins.scss';

:global {
    .MuiDateCalendar-root {
        background-color: var(--white-color);
        color: var(--primary-color);

        .MuiDayCalendar-weekDayLabel {
            color: var(--primary-color);
        }

        .MuiPickersCalendarHeader-root {
            .MuiButtonBase-root {
                color: var(--primary-color)
            }
        }


        .MuiDayCalendar-weekContainer {
            .MuiPickersDay-root {
                color: var(--primary-color);


                &.Mui-disabled {
                    color: var(--primary-color);
                }

                &.Mui-selected {
                    background-color: var(--accent-color) !important;
                }

                &.MuiPickersDay-today {
                    border-color: var(--accent-color);
                }

                &:hover {
                    background-color: var(--accent-light-color)
                }
            }
        }
    }
}

.box {
    margin: 10px 0;

    @include media-xl {
        margin: 22px 0;
    }

    @media (min-width: 1921px) {
        margin: 25px 0;
    }

    .birthday {
        background: transparent;
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
        align-items: center;

        fieldset {
            background-color: var(--white-color) !important;
            border: 1px #E7E7E7 solid !important;
            padding: 0;

            @include media-xxl {
                border-radius: 10px;
            }
        }

        input {
            color: #242628 !important;
            font-size: 12px;
            padding-bottom: 6px;
            padding-top: 6px;

            &::placeholder {
                color: #A3ADB1 !important;
            }

            @include media-xl {
                font-size: 16px;
                padding-bottom: 7px;
                padding-top: 7px;
            }

            @media (min-width: 1921px) {
                padding-bottom: 12px;
                padding-top: 12px;
                font-size: 18px;
            }
        }

        div {
            color: #242628 !important;
            font-size: 12px;
            @include media-xl {
                font-size: 16px;
            }

            @media (min-width: 1921px) {
                font-size: 18px;
            }
        }

        div[class^='DatePicker'] {
            width: 50%;
        }

        .MuiFormControl-root {
            width: 50%;
        }

        svg {
            fill:#242628
        }

        button {
            padding: 2px;
            @include media-xl {
                padding: 8px;
            }
        }
    }

    .location {
        background: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
            color: #242628 !important;
            font-size: 12px;

            @include media-xl {
                font-size: 16px;
            }

            @media (min-width: 1921px) {
                font-size: 18px;
            }
        }


        div[class^='Select_iconWrapper'] {
            width: 50%;
        }

        :global {
            .select__control, .select__menu {
                background-color: var(--white-color) !important;
                border: 1px #E7E7E7 solid !important;
                padding: 0;
                min-height: unset;

                @include media-xxl {
                    min-height: 38px;
                    border-radius: 10px;
                }
            }

            .select__value-container {
                padding-bottom: 0px !important;
                padding-top: 0px !important;

                @include media-xl {
                    padding-bottom: 2px !important;
                    padding-top: 2px !important;
                }

                @media (min-width: 1921px) {
                    padding-bottom: 7px !important;
                    padding-top: 7px !important;
                }
            }

            .select__placeholder {
                color: #A3ADB1 !important;
            }

            .select__indicator {
                padding: 2px;
                transform: scale(0.7);
                @include media-xl {
                    padding: 8px;
                    transform: scale(1);
                }
            }

            .select__input-container {
                margin: 0;
                @include media-xl {
                    margin: 2px;
                }
            }
        }
    }
}




