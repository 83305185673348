@import 'src/assets/styles/mixins.scss';

.box {
  padding: 15px 20px 15px 15px;
  border-radius: 10px;
  background-color: var(--secondary-color);

  @include media-xxl {
    padding: 20px 50px 20px 30px;
    border-radius: 15px;
  }


  .inner {
    display: flex;
    justify-content: space-between;
  }

  .plan {
     font-weight: 500;
     color: var(--white-color);
     font-size: var(--font-size-xs);

    @include media-xxl {
      font-size: 18px;
    }
  }

  .titleStatus {
    font-size: 24px;
    font-weight: 500;
    color: var(--white-color);
    margin-top: 16px;
    min-height: 29px;

    @include media-xxl {
      font-size: 38px;
      min-height: 44px;
    }
  }

  .endDate {
    font-size: var(--font-size-xs);

    @include media-xxl {
      font-size: var(--font-size-m);
    }
  }

  .logo {
    width: 119px;
    height: auto;

    path {
      fill: var(--white-color);;
    }
  }

  .btn {
    height: 34px;
    width: fit-content;
    margin-left: auto;
    padding: 10px 25px;
    border-radius: 5px;
    font-size: var(--font-size-sm);

    @include media-xxl {
      height: 50px;
      padding: 10px 40px;
      border-radius: 10px;
      font-size: var(--font-size-m);
    }
  }
}
