@import 'src/assets/styles/mixins.scss';

.container {
    display: flex;
    align-items: center;
    margin-top: 33px;
    

    @include media-xxl {
        margin-top: 48px;
    }
    
    .chart {
        flex: 1;

        
        :global {
            .apexcharts-legend-series {
                display: flex;
            }
            
            .pie-chart__legend {
                display: flex;
            }
        }

        .emptyPie {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 200px;
            margin-right: 10px;

            border-radius: 50%;
            background-color: var(--primary-color);
            color: var(--gray-dark-color);
            @include roboto-regular();

            @include media-xxl {
                width: 300px;
                height: 300px;
            }

            .innerPiece {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                width: 130px;
                height: 130px;
                padding: 20px;
                background-color: var(--secondary-color);
                font-size: var(--font-size-xs);
                text-align: center;

                @include media-xxl {
                    font-size: var(--font-size-sm);
                    width: 220px;
                    height: 220px;
                }
                
            }
        }
    }

    .legend {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        color: var(--white-color);

        .item {
            width: 50%;
            margin-bottom: 18px;

            @include media-xxl {
                margin-bottom: 25px;
            }

            .title {
                font-size: var(--font-size-s);
                color: var(--gray-dark-color);
                margin-bottom: 7px;
                text-transform: capitalize;
                //white-space: nowrap;
                @include roboto-medium();

                @include media-xxl  {
                    font-size: 17px;
                    margin-bottom: 12px;
                }
            }

            .stats {
                display: flex;
                align-items: center;
                white-space: nowrap;

                .marker {
                    width: 4px;
                    height: 4px;
                    background-color: red;
                    border-radius: 50%;
                    margin-right: 7px;
                    

                    @include media-xxl {
                        width: 7px;
                        height: 7px;
                        margin-right: 14px;
                    }
                }

                .earningsMoney {
                    font-size: calc(var(--font-size-m) - 1px);
                    margin-right: 5px;
                    @include roboto-medium();

                    @include media-xxl {
                        font-size: var(--font-size-heading1);
                        margin-right: 10px;
                    }
                }

                .percents {
                    align-self: flex-end;
                    color: var(--gray-dark-color);
                    font-size: var(--font-size-s);
                    margin-bottom: 3px;
                    @include roboto-medium();

                    @include media-xxl {
                        font-size: 17px;
                    }
                }
            }

            &.total {
                padding-top: 20px;
                border-top: 1px solid #474B56;

                .marker {
                    display: none;
                }

                .percents {
                    display: none;
                }
            }
        }
    }
}
