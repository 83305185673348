@import 'src/assets/styles/mixins.scss';

.container {
    width: 100%;
    .input {
        margin-top: 20px;
    }

    .name, .location {
        width: 100%;
        display: flex;
        gap: 14px;

        .input {
            flex: 1;

        }
    }

    .businessPurchase {
        margin-top: 10px;
        margin-left: 4px;

        @include checkbox-label();
    }
}