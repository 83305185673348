@import 'src/assets/styles/mixins.scss';

.box {
    width: 100%;
    padding: 20px;
    width: var(--modal-width);

    @include media-xxl {
        padding: 25px;
    }

    .header {
        display: flex;
        justify-content: flex-end;
        background: transparent;
        outline: none;
        border: none;
    }


    .close {
        position: relative;
        bottom: 4px;
        width: 9px;
        height: 9px;
        
        cursor: pointer;

        @include media-xxl {
            width: auto;
            height: auto;
        }
    }

}

.body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    padding: 10px 0 20px 0;

    @include media-xxl {
        gap: 27px;
    }

    .icon {
        width: 48px;
        height: 48px;
        height: auto;

        @include media-xxl {
            width: 80px;
            height: 80px;
        }
    }

    .text {
        font-size: var(--font-size-heading);
    }

    @include media-xxl {
        gap: 25px;
    }
}