
@mixin roboto-regular {
    font: {
        family: "Roboto";
        weight: 400;
        style: normal;
    }
}

@mixin roboto-medium {
    font: {
        family: "Roboto";
        weight: 500;
        style: normal;
    }
}


@mixin roboto-light {
    font: {
        family: "Roboto";
        weight: 300;
        style: normal;
    }
}

@mixin roboto-light-italic {
    font: {
        family: "Roboto";
        weight: 300;
        style: italic;
    }
}

// Use scsss variables for screen size just because CSS variables don't work for media
// Also this doesn't work: $var = var(--css-var)
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1280px;
$screen-xxl: 1500px;
$screen-xxl-extra: 1600px;

// Small devices
@mixin media-sm {
    @media (max-width: #{$screen-sm}) {
        @content;
    }
}

// Medium devices
@mixin media-md {
    @media (min-width: #{$screen-md}) {
        @content;
    }
}

// Large devices
@mixin media-lg {
    @media (min-width: #{$screen-lg}) {
        @content;
    }
}

// Extra large devices
@mixin media-xl {
    @media (min-width: #{$screen-xl}) {
        @content;
    }
}

// Extra large devices
@mixin media-xxl {
    @media (min-width: #{$screen-xxl}) {
        @content;
    }
}

// Extra large devices
@mixin media-xxl-extra {
    @media (min-width: #{$screen-xxl-extra}) {
        @content;
    }
}

@mixin field-label {
    margin-bottom: 8px;
    font-size: var(--font-size-m);
    font-weight: 500;
    color: var(--black-color);
}

@mixin checkbox-label {
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    input+label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        font-size: var(--font-size-xs);
        cursor: pointer;

        @include media-xxl {
            font-size: 17px;
        }
    }

    input+label::before {
        content: '';
        display: inline-block;
        width: 0.8em;
        height: 0.8em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid var(--border-color);
        border-radius: 0.25em;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;

        @include media-xxl() {
            width: 1em;
            height: 1em;
        }
    }

    input:checked+label::before {
        border-color: var(--accent-color);
        background-color: var(--accent-color);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
}
