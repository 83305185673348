@import 'src/assets/styles/mixins.scss';

.RequestForm {
    width: 100%;

    .title {
        margin-bottom: 46px;
        color: var(--accent-color);
        font-size: 20px;
        @include roboto-medium();

        @include media-xxl {
            font-size: var(--font-size-heading1);
            margin-bottom: 95px;
        }
    }

    .input {
        margin-top: 20px;

        @include media-xxl {
            margin-top: 25px;
        }
    }

    a {
        display: block;
        color: var(--accent-color);
        font-size: var(--font-size-m);
        margin-top: 15px;
    }

    .button {
        margin-top: 15px;

        @include media-xxl {
            margin-top: 28px;
        }
        
    }

    .privacyPolicy {
        margin-top: 6px;

        @include checkbox-label();

        @include media-xxl {
            margin-top: 17px;
        }

        label {
            align-items: flex-start !important;
        }

        a {
            display: contents;
            text-decoration: none;
            color: var(--accent-color);
            font-size: inherit;
        }
    }
}