@import 'src/assets/styles/mixins.scss';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    width: 100%;
    height: 100vh;
    background-color: var(--primary-color);
    font-size: var(--font-size-heading0);

    @include media-xxl {
        font-size: 32px;
    }

   

    @include roboto-medium();
}