
@import 'src/assets/styles/mixins.scss';


.field {
    width: 100%;
    height: auto;

    .selector {
        max-height: 41px;//fix
        height: 100%;
        position: relative;
        width: 100%;
        padding: 11px 32px 10px 10px;
        border: 1px solid var(--primary-color);
        border-radius: 4px;
        background-color: var(--primary-color);
        color: var(--white-color);
        white-space: nowrap;


        display: flex;
        align-items: center;

        .chevronIcon {
            height: 100%;
            position: absolute;
            right: 10px;
            color: var(--white-color);
            width: 17px;

        }

        &.open {
            transition: border-color .2s;
            border-color: var(--accent-color);
            .chevronIcon {
                transform: rotate(180deg);
                color: var(--accent-color);
            }
        }

        cursor: pointer;

        @include media-xxl {
            max-height: 50px;//fix
        }
    }
}

.paper {
    background-color: var(--primary-color) !important;
    border-radius: 10px;
}
.picker {
    --rdp-accent-color: var(--accent-subtle-color) !important; // fix
    color: var(--white-color);

    :global {
        .rdp-head {
            color: var(--gray-dark-color)
        }

        .rdp-day {
            &:hover {
                background-color: var(--accent-subtle-color);
            }
        }
        .rdp-day_selected {
            color: inherit;

            &:hover {
                background-color: var(--accent-subtle-color);
            }

            &.rdp-day_range_start,
            &.rdp-day_range_end {
                position: relative;
                color: var(--white-color);
                background-color: var(--accent-color);

                /*&::after {
                    position: absolute;
                    content: "content";
                    width: 30px;
                    height: 30px;
                    background-color: var(--accent-color);
                    border-radius: 50%;
                }*/

            }
        }

        .rdp-nav_icon {
            width: 10px;
            height: 10px;
        }

        .rdp-caption_label {
            font-size: var(--font-size-m);
        }

        .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
            background-color: transparent;
        }
    }
}