
@import 'src/assets/styles/mixins.scss';

.field {

    :global {
        .MuiInputBase-root {
            width: 100%;
            color: var(--white-color);
            background-color: var(--primary-color);
            font-size: inherit;

            fieldset {
                outline: none;
                border: 1px solid var(--primary-color) !important;
            }

            .MuiSelect-icon {
                color: var(--white-color);
                position: static;
                height: 100%;
                margin-right: 10px;
            }

            &.Mui-focused {
                fieldset {
                    transition: border-color .2s;
                    border-color: var(--accent-color) !important;
                }

                .MuiSelect-icon {
                    color: var(--accent-color);
                }
            }
        }

        .MuiSelect-select {
            padding: 12px;
        }
    }
}

.menu {
    :global {
        .MuiPaper-root {
            color: var(--white-color);

            .Mui-selected {
                background-color: rgba(91, 192, 235, 0.05) !important; 
                color: var(--accent-color);
            }

            .MuiCheckbox-root {
                color: var(--accent-color);
            }
        }
    }
}
