@import 'src/assets/styles/mixins.scss';

.btn {
    height: 34px;
    width: fit-content;
    margin-left: auto;
    padding: 10px 30px;
    margin-top: 10px;
    font-size: var(--font-size-xs);
    cursor: pointer;

    @include media-xxl {
        height: 50px;
        padding: 10px 50px;
        font-size: var(--font-size-m);
    }
}

.countryBox {
    display: flex;
    gap: 8px;
    color: var(--white-color);
    font-size: var(--font-size-xs);
    width: 100%;

    @include media-xxl {
        font-size: var(--font-size-m);
    }



    .proxyCountry {
        font-weight: 400;
    }


    .country {
        color: var(--accent-color);
        font-weight: 600;
    }
}


.titleBox {
    display: flex;
    gap: 15px;
    align-items: center;
    width: 100%;

    @include media-xxl {
        gap: 24px;
    }

    .info {
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;

        circle {
            fill: var(--error-color);
        }

        path {
            stroke: var(--error-color);
        }
    }
}

