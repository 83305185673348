@import 'src/assets/styles/mixins.scss';

.AgencyForm {
    width: 100%;

    .title {
        margin-bottom: 46px;
        color: var(--accent-color);
        font-size: 20px;
        @include roboto-medium();

        @include media-xxl {
            font-size: var(--font-size-heading1);
            margin-bottom: 95px;
        }
    }

    .input {
        margin-top: 15px;

        @include media-xxl {
            margin-top: 30px;
        }
    }

    .soloCreator {
        margin-top: 6px;

        @include checkbox-label();

        @include media-xxl {
            margin-top: 10px;
        }

        
    }

    a {
        display: block;
        color: var(--accent-color);
        font-size: var(--font-size-m);
        margin-top: 15px;
    }

    .button {
        margin-top: 15px;
        margin-bottom: 5px;

        @include media-xxl {
            margin-top: 21px;
            margin-bottom: 10px;
        }
    }
}