@import 'src/assets/styles/mixins.scss';

.container {
    width: var(--modal-width);
    padding: 20px;

    @include media-xxl {
        padding: 25px;
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        svg {
            cursor: pointer;
        }

        .close {
            width: 9px;
            height: 9px;

            @include media-xxl {
                width: auto;
                height: auto;
            }
        }
    }
}

.body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {

        @include media-xxl {
            margin-bottom: 10px;

        }

        svg {
            width: 20px;
            height: 20px;

            @include media-xxl {
                width: 30px;
                height: 30px;
            }
        }
    }

    .title {
        margin-bottom: 10px;
        font-size: var(--font-size-heading);
        font-weight: 500;
    }

    .description {
        margin-bottom: 15px;
        max-width: 300px;
        text-align: center;
        @include roboto-light();

        @include media-xxl {
            margin-bottom: 25px;
            max-width: 500px;
        }
    }

    .actions {
        width: 100%;
        padding: 0 20px;

        @include media-xxl {
            padding: 0 40px;
        }

        .assignCreator {
            display: flex;
            margin-bottom: 10px;
            width: 100%;

            @include media-xxl {
                margin-bottom: 20px;
            }

            .input {
                flex: 3;
            }

            button {
                margin-left: 10px;
                flex: 1;
            }
        }

        .creatorsList {
            width: 100%;

            .creator {
                display: flex;
                justify-content: space-between;
                padding: 14px 0;
                border-bottom: 1px solid var(--dark-border-color);
                align-items: center;

                @include media-xxl {
                    padding: 20px 0;
                }

                .info {
                    display: flex;

                    .avatar {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color: var(--gray-color);
                        margin-right: 10px;

                        @include media-xxl {
                            width: 50px;
                            height: 50px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }

                    .username {

                        .ofNick {
                            color: var(--gray-dark-color);
                            font-size: var(--font-size-xs);
                            line-height: 10px;

                            @include media-xxl {
                                font-size: 16px;
                                line-height: 28px;
                            }
                        }

                        .name {
                            color: var(--white-color);
                            font-size: var(--font-size-s);

                            @include media-xxl {
                                font-size: var(--font-size-m);
                                
                            }
                        }


                    }
                }

                .removeBtn {
                    max-width: 90px;
                    max-height: 30px;

                    @include media-xxl {
                        max-width: 140px;
                        max-height: 40px;
                    }

                }
            }
        }
    }

}