@import 'src/assets/styles/mixins.scss';

.box {
    min-width: 580px;
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    min-height: 160px;

    @include media-xxl {
        padding: 25px;
        min-width: 870px;
        gap: 24px;
        min-height: 212px;
    }

    .header {
        display: flex;
        justify-content: center;
        background: transparent;
        outline: none;
        border: none;
    }

    .close {
        position: absolute;
        cursor: pointer;
        right: 25px;
        top: 20px;
    }

    .icon {
        width: 20px;
        height: 20px;

        @include media-xxl {
            width: 30px;
            height: 30px;
        }
    }
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    .title {
        font-size: var(--font-size-heading);
        font-weight: 500;

        @include media-xxl {
            font-size: var(--font-size-heading);
        }
    }


    .text {
        font-size: var(--font-size-sm);
        font-weight: 300;
        max-width: 350px;
        text-align: center;

        @include media-xxl {
            font-size: var(--font-size-m);
            max-width: unset;
        }
    }
}
