@import 'src/assets/styles/mixins.scss';

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;
    
    outline: none;
    border: 0;
    width: 100%;
    transition: all .3s;
    position: relative;

    @include media-xxl {
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 10px;
    }
}

.clear {
    background-color: var(--gray-color);
    color: var(--black-color);

    &:active {
        background-color: var(--gray-light-color);
    }
}

.primary {
    background-color: var(--accent-color);
    color: var(--white-color);

    &:hover {
        background-color: #3ACAFF;
    }

    &:active {
        background-color: #0A8BBB;
    }

    &:disabled {
        cursor: default;
        background-color: var(--gray-color);
    }
}

.outline, .darkOutline {
    color: var(--gray-dark-color);
    border: 1px solid var(--gray-dark-color);
    background-color: var(--white-color);

    &:hover {
        color: var(--accent-color);
        border-color: var(--accent-color);
    }

    &:active {
        border-color: #0A8BBB;
        color: #0A8BBB;
    }

    &:disabled {
        cursor: default;
        border-color: var(--gray-dark-color);
        color: var(--gray-dark-color);
    }
}

.darkOutline {
    background-color: transparent;
    border-color: var(--white-color);
    color: var(--white-color);
}


.loader {
    position: absolute;
    display: none !important;
}

.loading {
    .children {
        visibility: hidden;
        z-index: -1;
    }

    .loader {
        display: inline-block !important;
        width: 15px !important;
        height: 15px !important;

        @include media-xxl {
            width: 24px !important;
            height: 24px !important;
        }
    }
}


.size_m {
    font-size: inherit;
}



