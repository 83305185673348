@import 'src/assets/styles/mixins.scss';

.statistics {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
    overflow: hidden;

    padding: 30px 40px;

    @include media-xxl {
        padding: 30px 60px;
    }


    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;


        .title {
            font-size: var(--font-size-m);
            color: var(--white-color);
            margin-right: 27px;

            @include roboto-medium();

            @include media-xxl {
                font-size: var(--font-size-heading1);
            }
        }

        .location {
            display: flex;
            align-items: center;
            font: var(--font-m);
            color: var(--gray-dark-color);
        }
    }

    .filters {
        display: flex;
        gap: 15px;
        width: 100%;
        padding: var(--content-padding);
        background-color: var(--secondary-color);
        border-radius: 15px;
        box-shadow: var(--block-shadow);
        margin-bottom: 20px;


        @include media-xxl {
            gap: 25px;
            margin-bottom: 30px;
        }

        .filterInput {
            flex: 1;
        }
        
        .chipField {
            margin-top: 6px;
            height: auto !important;
            flex-wrap: nowrap;

            @include media-xxl {
                margin-top: 10px;
            }
        }


    }

    .row {
        display: flex;
        //flex-wrap: wrap;
        gap: 20px;

        @include media-xxl {
            gap: 30px;
        }

        .earningBreakdown {
            flex: 2
        }
    
        .earningStats {
            flex: 1
        }

        .bestDay {
            flex: 1;
            //max-width: 50%;
            //min-width: 50%;
        }

        .bestHour {
            flex: 1;
           // max-width: 50%;
            //min-width: 50%;
        }

    }


}

.accountItem {
    display: flex;
    align-items: center;
    .avatar {
        width: 35px;
        height: 35px;
        background-color: var(--gray-color);
        border-radius: 50%;
        margin-right: 10px;

        img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .name {
        font-size: inherit;
    }
    
    .ofNick {
        font-size: var(--font-size-sm);
        color: var(--gray-dark-color);
    }
}