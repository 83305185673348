@import 'src/assets/styles/mixins.scss';

.container {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    gap: 20px;

    @include media-xxl {
        gap: 26px;
        margin-bottom: 30px;
    }


    .tile {
        display: flex;
        align-items: center;
        padding: 15px;
        flex: 1;
        background-color: var(--secondary-color);
        color: var(--white-color);
        border-radius: 10px;

        @include media-xxl {
            padding: 24px;
        }

        .icon {
            width: 20px;
            height: 20px;
            margin-right: 13px;

            @include media-xxl {
                width: 35px;
                height: 35px;
                margin-right: 20px;
            }
        }

        .info {
            .value {
                font-size: 22px;
                @include roboto-medium();

                @include media-xxl {
                    font-size: 36px;
                }
            }

            .data {
                height: 26px;

                @include media-xxl {
                    height: 42px;
                }
            }

            p {
                white-space: nowrap;

                &:first-child {
                    margin-bottom: 6px;
                }
            }
        }
    }
}