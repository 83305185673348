@import 'src/assets/styles/mixins.scss';

.container {
    width: 100%;

    .area {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 5px;

        width: 100%;
        padding: 10px;
        border-radius: 5px;
        background-color: var(--primary-color);


        @include media-xxl {
            padding-top: 20px;
            padding-bottom: 20px;
            border-radius: 10px;
        }

        .chip {
            padding: 0px 10px;
            border: 1px solid #474B56 ;
            border-radius: 3px;
            white-space: nowrap;
            color: var(--white-color);
            cursor: pointer;
            font-size: inherit;
            @include roboto-regular();


            &.selected {
                background-color: var(--accent-color);
                @include roboto-medium();
            }

            @include media-xxl {
                padding: 7px 15px;
                border-radius: 5px;
            }
        }

        &::-webkit-scrollbar {
            display: none;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
    }

    &.clear {

        .area {
            height: 100%;

            padding: 4px;
            background-color: transparent;
            .chip {
                display: flex;
                align-items: center;
                justify-content: center;
                max-height: 34px;
                padding: 8px;
                border: 1px solid var(--dark-border-color);
                font-size: var(--font-size-xs);
                color: var(--white-color);
                border-radius: 5px;

                

                @include media-xxl {
                    font-size: var(--font-size-sm);
                    padding: 9px 13px;
                }
            }
        }
    }
    

    &.error {
        .area {
            border: 1px solid var(--error-color);

        }
    }
}
