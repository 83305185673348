@import 'src/assets/styles/mixins.scss';

.container {
    width: 450px;
    position: relative;
    padding: 20px;

    @include media-xxl {
        padding: 25px;
        width: 640px;
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        //align-items: center;

        .logo {
            margin: 0 auto;
            width: 100px;
            height: 75px;


            img {
                width: 100%;
                height: 100%;
            }
        }

        .close {
            position: absolute;
            cursor: pointer;
            right: 40px;
        }

    }

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .funds {
            display: flex;
            align-items: center;
            color: var(--white-color);
            font-size: var(--font-size-l);
            font-weight: 600;

            .icon {
                margin-right: 10px;
            }
        }

        .title {
            margin: 6px 0;
            color: var(--white-color);
            font-size: var(--font-size-heading);
            font-weight: 600;

            @include media-xxl {
                margin-bottom: 20px;
            }
        }

        .description {
            margin-bottom: 20px;
            color: var(--gray-dark-color);
            font-size: inherit;

            @include media-xxl {
                margin-bottom: 30px;
            }
        }

        .order {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-bottom: 10px;
            color: var(--gray-dark-color);
            font-size: var(--font-size-m);
            font-weight: 600;
            border-bottom: 1px solid var(--border-color);

            span {
                display: block;
                margin-left: 4px;
                color: var(--accent-color);
            }
        }

        .actions {
            gap: 20px;
            display: flex;
            justify-content: center;
            width: 100%;

            @include media-xxl {
                gap: 30px;
            }

            button {
                max-width: 141px;

                @include media-xxl {
                    
                    max-width: 200px;
                }
            }
        }

    }

}
