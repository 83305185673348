@import 'src/assets/styles/mixins.scss';

.noData {
    color: var(--white-color);
    font-size: var(--font-size-sm);

    @include media-xxl {
       font-size: var(--font-size-m);
    }
}
