@import 'src/assets/styles/mixins.scss';
@import 'src/assets/styles/variables.module';

.overlay {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--modal-z-index);
}

.body {
    width: 100%;
    border-radius: 10px;
    background: var(--secondary-color);
    color: var(--white-color);
    //padding: 30px; flexible modals

    max-height: 948px;

    &:focus-visible {
        outline: none;
    }
    //overflow-y: auto;
    &::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: var(--white-color);
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--accent-color);
        border-radius: 10px;
    }

}

.modal {
    //width: var(--modal-width); flexible modals

    &:focus-visible {
        outline: none;
    }

    &.flexible {
        width: auto;

        .body {
            padding: 0;
        }
    }
}
