@import 'src/assets/styles/mixins.scss';

.select {
    min-width: 246px;

    .iconWrapper {
        position: relative;
        width: 100%;

        .icon {
            position: absolute;
            top: 15px;
            left: 10px;
            z-index: 1;
        }
    }

    .control {

        :global {
            .select__indicator-separator {
                display: none;
            }

            .select__control {
                box-shadow: none;
                border-color: var(--border-color);
                font-size: inherit;

                &--is-focused {
                    transition: border-color .2s;
                    border-color: var(--accent-color) !important;

                    .select__dropdown-indicator {
                        color: var(--accent-color);
                    }
                }

                .select__value-container {
                    padding: 6px 0px 6px 6px;
                }

                .select__placeholder {
                    font-style: italic;
                }


                @include media-xxl {
                    .select__value-container {
                        padding-top: 16px;
                        padding-bottom: 16px;
                    }


                    border-radius: 10px;
                }

            }

            .select__menu {
                .select__option {
                    padding: 10px 20px;
                }

                .select__option--is-selected {
                    background-color: var(--accent-color);
                }

            }

            .select__input {
                opacity: 1 !important;
            }
        }

        &.notSearchable {
            :global {
                .select__control {
                    .select__value-container {
                        padding: 12px;

                        @include media-xxl {
                            padding-top: 22px;
                            padding-bottom: 22px;

                        }
                    }
                }
            }

        }


        &.error {
            :global {
                .select__control {
                    border-color: var(--error-color) !important;
                }
            }
        }

        &.withIcon {
            :global {
                .select__control {
                    .select__value-container {
                        padding-left: 40px;
                    }
                }
            }
        }
    }

    &:global(.dark) {

        .control {
            :global {
                .select__control {
                    background-color: var(--primary-color);
                    border-color: var(--primary-color);

                    .select__input-container {
                        color: var(--white-color) !important;
                    }

                    .select__single-value {
                        color: var(--white-color);
                    }
                }

                .select__menu {
                    background-color: var(--primary-color);
                    border-radius: 10px;

                    .select__option {
                        color: var(--white-color);
                    }

                    .select__option--is-selected {
                        background-color: rgba(91, 192, 235, 0.05);
                        color: var(--accent-color);
                    }

                    .select__menu-list::-webkit-scrollbar {
                        display: none;
                    }

                    .select__option--is-focused {
                        background-color: rgba(91, 192, 235, 0.05);
                    }

                }

                .select__placeholder {
                    color: var(--gray-dark-color);
                }
            }
        }
    }
}
