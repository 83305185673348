@import 'fonts';
@import 'src/assets/styles/mixins.scss';

:root {
    --font-family-main: "Roboto", serif, "Times New Roman";

    --font-size-xs: 10px;
    --font-size-s: 12px;
    --font-size-sm: 14px;
    --font-size-m: 19px;
    --font-size-l: 24px;
    --font-size-xl: 32px;

    --font-line-xs: 24px;
    --font-xs: var( --font-size-s) / var(--font-line-xs) var(--font-family-main);

    --font-line-m: 24px;
    --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);

    --font-line-l: 32px;
    --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);

    --font-line-xl: 40px;
    --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);

    // TEMP
    --font-size-heading: 16px;
    --font-size-large-heading: 26px;
    --font-size-heading0: 24px;
    --font-size-heading1: 28px;

    --primary-color: #22272D;
    --secondary-color: #34383F;

    --accent-dark-color: #088FC1;
    --accent-color: #5BC0EB;
    --pink-color: #FF007A;
    --accent-light-color: #00aff073;
    --accent-subtle-color: rgba(91, 192, 235, 0.05);

    --error-color: #FD3A45;
    --border-color: #E0E0E0;
    --dark-border-color: #515151;

    --gray-dark-color: #A3ADB1;
    --gray-color: #DADADA;
    --gray-light-color: #F0F0F0;
    --gray-transparent-color: #FAFAFA;

    --white-color: #FFFFFF;
    --black-color: #242628;
    --slightly-transparent-color: rgba(255, 255, 255, 0.80);

    --block-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);


    // z-index
    --modal-z-index: 10;
    --tooltip-z-index: 9;

    //width
    --extention-width: 340px;
    --modal-width: 580px;
    --sidebar-width: 215px;

    //spacing
    --content-padding: 20px;

    @include media-xxl {
        --sidebar-width: 320px;
        --font-size-heading: 26px;
        --modal-width: 870px;
    }
}

:export {
    accentColor: var(--accent-color);
    secondaryColor:var(--secondary-color);
    primaryColor: var(--primary-color);
    textColor: var(--white-color);
}
