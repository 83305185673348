* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

input,
button,
textarea,
select {
    margin: 0;
    font: inherit;
}
