@import 'src/assets/styles/mixins.scss';

@keyframes pulse {
    0% {
        transform: scale(0.7);
    }

    100% {
        transform: scale(1);
    }
}

.BrandCanvas {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
    flex: 1;
    max-height: 100vh;

    img {
        max-width: 263px;
        max-height: 192px;
        object-fit: contain;
        height: 100%;
        width: 100%;
        padding: 0 20px;

        
        @include media-xxl {
            max-width: 482px;
            max-height: 350px;
        }
    }

    &.initializing {
        height: 100vh;
        animation-direction: alternate;

        img {
            width: 50%;
            animation-name: pulse;
            animation-duration: 1.5s;
            animation-direction: alternate;
            animation-iteration-count: infinite;
            animation-fill-mode: both;
        }

    }
}