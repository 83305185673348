@import 'src/assets/styles/mixins.scss';

.payment {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    @include media-xxl {
        gap: 30px;
    }

    .card {
        padding: 15px;
        background-color: var(--secondary-color);
        color: var(--white-color);
        border-radius: 10px;

        @include media-xxl {
            padding: 20px;
            border-radius: 15px;
        }

        .title {
            margin-bottom: 7px;
            font-size: 12px;
            font-weight: 400;

            @include media-xxl {
                margin-bottom: 10px;
                font-size: 19px;
            }
        }

        .icon {
            margin-bottom: 10px;

            svg {
                width: 16px;
                height: 16px;

                @include media-xxl {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        &.balance {
            flex: 1;

            .value {
                font-size: 25px;
                font-weight: 600;
                margin-bottom: 20px;

                @include media-xxl {
                    font-size: var(--font-size-xl);
                }
            }

            .description {
                font-size: 10px;
                color: var(--gray-dark-color);

                @include media-xxl {
                    font-size: var(--font-size-sm);
                }
            }
        }

        &.funds {
            display: flex;
            flex-direction: column;
            flex: 2;

            .paymentMethod {
                display: flex;
                gap: 10px;
                margin-bottom: 7px;

                @include media-xxl {
                    gap: 16px;
                    margin-bottom: 14px;
                }

                .select {
                    :global {
                        .select__control {
                            min-height: 34px;

                            .select__value-container {
                                height: 34px;
                                padding-top: 0;
                                padding-bottom: 0;

                                @include media-xxl {
                                    height: 50px;
                                }
                            }

                            .select__dropdown-indicator {
                                padding: 6px;
                            }
                        }
                    }
                }

                input {
                    height: 36px;
                    padding-top: 0;
                    padding-bottom: 0;

                    @include media-xxl {
                        height: 50px;
                    }
                }

                input,
                .control {
                    flex: 1;
                    min-width: auto;
                }
            }
        }

        &.billingHistory {
            flex: 2;

            .nextPayment {
                font-size: var(--font-size-s);
                color: var(--gray-dark-color);
                font-weight: 400;
                padding-top: 10px;
                padding-bottom: 20px;

                @include media-xxl {
                    font-size: 16px;
                    padding-top: 12px;
                    padding-bottom: 22px;
                }
            }

            .history {
                display: flex;
                justify-content: space-between;

                .accounts {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-right: 10px;
                    span {
                        max-width: 220px;
                        font-size: var(--font-size-xs);
                        color: var(--gray-dark-color);
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;

                        @include media-xxl {
                            font-size: 16px;
                        }
                    }

                    p {
                        margin-bottom: 4px;
                        span {
                            font-size: var(--font-size-s);
                            color: var(--accent-color);
                            font-weight: 400;

                            @include media-xxl {
                                font-size: 18px;
                            }
                        }
                    }
                }

                .billingHistoryBtn {
                    width: initial;
                    max-height: 50px;

                    @include media-xxl {
                        width: 190px;
                    }
                }
            }
        }
    }

    .paymentBtn {
        max-height: 50px;
    }
}
