@import "reset";
@import "variables.module.scss";
@import "mixins";


body {
    font: var(--font-xs);

    &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    @include media-xxl {
        font: var(--font-m);
    }

}

.app {
    min-height: 100vh;
    display: flex;
    overflow-x: hidden;
    line-height: normal;
}

.page-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--primary-color);
    margin-left: var(--sidebar-width);

}

textarea {
    resize: none;
}